import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import StorageIcon from '@material-ui/icons/Storage';
import React, { FunctionComponent } from 'react';
import IFrameComponent from '../i-frame-component';

// @ts-ignore
export interface StorageDashboardProps {}

const HEIGHT_SM = '220px';
const HEIGHT = '350px';
const WIDTH = '180px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
  })
);

const text = {
  title: 'Storage',
  storageCapacityTitle: 'Storage Capacity',
  storageCapacity:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=12',
  storageUsageTitle: 'Storage Usage',
  storageUsage:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=14"',
  storageUsageGraphTitle: 'Storage Usage',
  storageUsageGraph:
    'https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=17',
};

export const StorageDashboard: FunctionComponent<StorageDashboardProps> = () => {
  const classes = useStyles();

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <Grid container direction={'row'} spacing={1}>
              <Grid item>
                <StorageIcon />
              </Grid>
              <Grid>
                <Typography color="secondary" variant="h5">
                  {text.title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.storageCapacityTitle}
            src={text.storageCapacity}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.storageUsageTitle}
            src={text.storageUsage}
          />
        </Grid>
        <Grid item xs={12}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.storageUsageGraphTitle}
            src={text.storageUsageGraph}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StorageDashboard;
